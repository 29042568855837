@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'NEXT';
    src: url('../../public/fonts/NEXT-Book-Light.otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'NEXT';
    src: url('../../public/fonts/NEXT-Book-Medium.otf');
    font-weight: 500;
    font-style: normal;
}

body {
    height: 100vh;
    width: 100vw;
    background-color: black;
    font-family: 'NEXT';
    font-weight: 300;
    color: white;
}

#root {
    height: 100vh;
}

div::-webkit-scrollbar {
    display: none;
}

.chart-container {
    background-image: linear-gradient(to right, transparent calc(10% - 1px), rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.2) calc(15% - 1px), transparent 0); /* Create 10 vertical lines */
    background-size: 11% 100%; /* Set the size of the lines */
    background-repeat: repeat-x; /* Repeat the lines horizontally */
    animation: moveBackground 112s linear infinite; /* Animate the background */
}

@keyframes moveBackground {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -100% 0;
    }
}
